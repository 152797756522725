<!-- 视图视图定制-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template >
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt">{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false"  @click="switchCategory(1)" :type="query.category===1?'primary':'default'">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shitudingzhi" font-size="27px;"/>
                            <span class="txt">
                                <!-- {{msg('库存Tab')}}<br v-if="scope.ctrl.expand" /> -->
                            {{msg("库存视图")}}</span>  
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="switchCategory(0)"  :type="query.category===0?'primary':'default'">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-dengluciyonghu" font-size="27px;"/>
                            <span class="txt">
                                <!-- {{msg('账户Tab')}}<br v-if="scope.ctrl.expand" /> -->
                            {{msg("账户视图")}}</span>      
                        </div>            
                    </el-link>
                    <!-- <el-dropdown @command="switchViewType">
                        <el-link :underline="false">
                            <div class="wrap" >
                                <i class="iconfont icon-caidan font22" ></i>
                                <span class="txt">{{query.ownerType.label}}</span>  
                            </div>              
                        </el-link>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in dicts.viewOnwerTypes" :key="item.value" :command="item.value">{{item.label}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                </div>
                <div class="xgroup">
                    <el-link :underline="false"  @click="doAdd()" v-if="hasPermission('view:create')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()" v-if="hasPermission('view:update')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doDelete()" v-if="hasPermission('view:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-show="ctrlData.sort" :underline="false"  @click="doSort()" v-if="hasPermission('view:sort')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-paixu1" font-size="27px;"/>
                            <span class="txt">{{msg('确定排序')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false"  @click="setUsually()" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chuhuohedui" font-size="27px;"/>
                            <span class="txt">{{msg('设置常用')}}</span>      
                        </div>            
                    </el-link>
                    <el-link v-show="query.category == 1 && curr" :underline="false"  @click="setSpecFlag()" v-if="hasPermission('view:specFlag')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-buju1" font-size="27px;"/>
                            <span class="txt">{{msg('设置为库存视图')}}</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('视图名')">
                        <el-input v-model="scope.query.name"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" keep-source
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :columns="columns"
                @cell-click="checkRow"
                @edit-closed="doDefault"
                :edit-config="{trigger: 'click', mode: 'cell', showStatus: true, icon: 'fa fa-file-text-o'}"
            ></vxe-grid>
        </div>

        <el-dialog :title="dgTitle" :close-on-click-modal="false" 
            :visible.sync="dgVisible" width="600px"  @opened="openedEdit"
            :fullscreen="isMinScreen">
                <edit :tables="tables" :view-setting="curr" 
                    :query-tables = "queryTables" 
                    :stock-btns = "stockBtns"
                    :category="query.category"
                    :filter-option-types="filterOptionTypes"
                    @close-dg="closeEditDialog" ref="editDg"/>
        </el-dialog>

    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Edit from "./ViewSettingDetail.vue";
export default {
    name: 'ViewSetting',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Edit: Edit
    },
    mounted(){
        this.buildColumn();
        this.list();
    },
    data(){
        return {
            query:{
                name: null,
                category: 1,  //0-user, 1-store
                ownerType:{label: "所有视图", value: 0}
            },

            showQuery: false,
            treeConfig: {
                children: "children",
                line: true,
            },
            datas: [],      //展示列表
            origins: [],
           
            dgTitle: '',
            dgVisible: false,
            curr: null,

            userViews: null,  //当前登录用户所拥有的账户字段
            userQueries: null,//当前登录用户所拥有的账户查询条件
            storeViews: null,   //当前登录用户所拥有的库存字段
            storeQueries: null, //当前登录用户所拥有的库存查询条件
            stockBtns: null, //当前登录用户所拥有的库存按钮
            tables: null,  //待分配的字段
            queryTables: null,  //待分配的查询字段
            filterOptionTypes: null,

            ctrlData: {
                sort: false,
            },
            columns: null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 160;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        moveUp(index){
            if(index > 0){
                let currSeq = this.datas[index].seq;
                let prevSeq = this.datas[index-1].seq;
                var curr = this.datas[index];
                var prev = this.datas[index-1];
                this.datas[index] = prev;
                this.datas[index - 1] = curr;
                this.ctrlData.sort = true;
                this.$refs.dataTable.reloadData(this.datas);
                // let data = [{id:prev.id,seq:currSeq},{id:curr.id,seq:prevSeq}];
                // this.updateSeq(data);
            }
        },
        moveDown(index){
            if(index < this.datas.length - 1){
                let currSeq = this.datas[index].seq;
                let prevSeq = this.datas[index+1].seq;
                var curr = this.datas[index];
                var next = this.datas[index+1];
                this.datas[index] = next;
                this.datas[index + 1] = curr; 
                this.ctrlData.sort = true;
                this.$refs.dataTable.reloadData(this.datas);
                // let data = [{id:next.id,seq:currSeq},{id:curr.id,seq:prevSeq}];
                // this.updateSeq(data);
            }
        },
        updateSeq(datas){//保存排序
             var _this = this;
             this.$axios.post(this.$kit.api.viewSetting.seq, datas)
                .then((result) => {
                    this.$refs.dataTable.reloadData(this.datas);
                    _this.$message(result.msg);
                })
        },
        buildColumn(){
            var _this = this;
            var user = localStorage.getItem("user");
            if(user){
                user = JSON.parse(user);
            }

            this.columns = [
                {field: "id", title: this.msg("ID"), minWidth: 80},
                {field: "name", title: this.msg("视图名"), minWidth: 135},
                {field: "defaultedName", title: this.msg("默认"), minWidth: 100, 
                    editRender: {
                        name: 'ElSelect', 
                        options: this.dicts.defaulteds, props: { clearable: true }, 
                        optionProps: {value: 'label', label: 'label'}, 
                        // events:{change: provinceChange}
                    }
                },
                {field: "creater", title: this.msg("来源"), minWidth: 100, 
                    slots: {
                        default: ({ row }) => {
                             return [
                                user && user.id == row.creater? <el-tag type="danger">自己</el-tag> : <el-tag type="danger">系统</el-tag>
                            ]
                        }
                    }
                },
                {field: "hasUsually", title: this.msg("是否常用"), minWidth: 100, 
                    slots: {
                        default: ({ row }) => {
                             return [
                                // row.hasUsually?"是":'否'
                                <el-switch
                                    v-model={row.hasUsually}
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    active-value={1}
                                    onChange={(value) => this.changeUsually(value,row.name,row.id)}
                                    inactive-value={0}>
                                </el-switch>
                            ]
                        }
                    }
                },
                {field: "seq", title: this.msg("排序"), minWidth: 80,
                    slots: {
                        default: ({ rowIndex }) => {
                            return this.buildSortCell(rowIndex);
                        }
                    }
                }
            ]
        },

        buildSortCell(rowIndex){
            return [
                <span>
                    <el-link underline={false} icon="el-icon-top" onClick={ ()=>this.moveUp(rowIndex) }></el-link>
                    <el-link icon="el-icon-bottom" style="margin-left:8px;" underline={false} onClick={ () => this.moveDown(rowIndex) }></el-link>
                </span>
            ]
        },
        list(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.viewSetting.list, params)
                .then((result) => {
                    if(result.status){
                        result.data.forEach(e => {
                            e['defaultedName'] = e.defaulted==1?'是':'否';
                            e['categoryName'] = e.category == 1?'库存视图':'用户视图';
                        });
                        this.origins = result.data;
                        this.filterData();
                    }
                });
        },
        getTables(type, callback){  //查询当前登陆用所拥有的字段，按钮，查询条件
            this.$axios.get(this.$kit.api.viewSetting.tables+"?t="+type)
                .then((result) => {
                    if(result.status){
                        if(type===0){
                            this.userViews = result.data;
                            this.userQueries = JSON.parse(JSON.stringify(result.data));
                        }else if(type === 1){
                            let columns = result.data.columns;
                            let btns = result.data.btns;
                            this.storeViews = columns;
                            this.storeQueries = JSON.parse(JSON.stringify(columns));
                            this.buildBtns(btns);  //
                        }
                        if(callback) callback();
                    }
                });
        },
        reset(){
            // this.$kit.clearQuery(this.query);
            this.query = {
                name: null,
                category: 1,  //0-user, 1-store
                ownerType:{label: "所有视图", value: 0}
            }
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            // if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                this.curr = row;
                // table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            // }
        },
        switchViewType(command){

            var item = this.dicts.viewOnwerTypes[command];
            this.query.ownerType = item;
            this.filterData();
            
        },
        switchCategory(category){
            this.query.category = category;
            this.list();
        },
        //根据条件，筛选现实的视图类型： 0-所有视图，
        filterData(){
            var filteds = null;
            if(this.query.ownerType.value === 0){
                filteds = this.origins;
            } else {
                filteds = this.origins.filter(o => o.ownerType == this.query.ownerType.value);
            }
            filteds.sort((a, b) => (a.seq || 0) - (b.seq || 0));        

            this.datas = filteds;
            this.$refs.dataTable.reloadData(this.datas);
        },
        //执行新增操作
        doAdd(){
            this.curr = {
                name: null,
                category: this.query.category,
                seq:0,
                details: [],
                queries: [],
                btns: [],
            }

            //获取数据
            this.prepareOpenDg(this.query.category, this.msg("新增视图"), 0);
        },
        //执行编辑操作
        doEdit(){
            // var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(!this.curr){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            // if(selectes.length>1){
            //     this.$message(this.msg('只能编辑一条数据'));
            //     return;
            // }
            

            this.$axios.get(this.$kit.api.viewSetting.get+"?id="+this.curr.id)
                .then((result) => {
                    if(result.status){
                        if(result.data.details){
                            result.data.details.forEach(d =>  {
                                d['show'] = true;
                                d['editIcon'] = false;
                            });
                        }
                        if(result.data.queries){
                            result.data.queries.forEach(d =>  {
                                d['show'] = true;
                                d['editIcon'] = false;
                            });
                        }
                        this.curr = result.data;

                        this.prepareOpenDg(this.query.category, 
                            this.msg("编辑视图")+":"+this.curr.name, 1);
                    }else{
                        this.$message(result.msg);
                    }
                });
        },
        //打开编辑之前，做数据初始化准备
        //type：0-新增，1-编辑，编辑的时候需要隐藏左边的在右边已经选中的。
        prepareOpenDg(category, title, type){
            if(category === 0){
                this.filterOptionTypes = this.dicts.filterUserOptionTypes;
                if(this.userViews){
                    this.tables = this.userViews;
                    this.queryTables = this.userQueries;
                    this.doTag(type);
                    this.dgTitle = title;
                    this.dgVisible = true;
                }else{
                    this.getTables(category, ()=>{
                        this.tables = this.userViews;
                        this.queryTables = this.userQueries;
                        this.doTag(type);
                        this.dgTitle = title;
                        this.dgVisible = true;
                    });
                }
            }else if(category === 1){
                this.filterOptionTypes = this.dicts.filterStoreOptionTypes;
                if(this.storeViews){
                    this.tables = this.storeViews;
                    this.queryTables = this.storeQueries;
                    this.doTag(type);
                    this.checkBtns(this.curr.btns); //选中按钮
                    this.dgTitle = title;
                    this.dgVisible = true;
                }else{
                    this.getTables(category, ()=>{
                        this.tables = this.storeViews;
                        this.queryTables = this.storeQueries;
                        this.checkBtns(this.curr.btns); //选中按钮
                        this.doTag(type);
                        this.dgTitle = title;
                        this.dgVisible = true;
                    });
                }
            }
        },
        doTag(type){
            //标记选中，和激活
            this.tables.forEach(t => {
                t.columns.forEach(c => {
                    if(type==1){
                        let selectedItem = this.curr.details.find(i => i.tableName == c.tableName && i.colName == c.colName && i.tableAlias == c.tableAlias);
                        if(selectedItem){
                            this.$set(c,'selected',true);
                        }else{
                            this.$set(c,'selected',false);
                        }
                    }else{
                        this.$set(c,'selected',false);
                    }
                    this.$set(c,'active',false);
                });
            });

            this.queryTables.forEach(t => {
                t.columns.forEach(c => {
                    if(type==1){
                        let selectedItem = this.curr.queries.find(i => i.tableName == c.tableName && i.colName == c.colName && i.tableAlias == c.tableAlias);
                        if(selectedItem){
                            this.$set(c,'selected',true);

                            if(selectedItem.op == 6 || selectedItem.op == 8){
                                if(selectedItem.dvalue){
                                    let vals = selectedItem.dvalue.split(",").map(i => (i*1 || i));
                                    this.$set(selectedItem,'dvalues', vals);
                                }else{
                                    this.$set(selectedItem,'dvalues', []);
                                }
                            }else{
                                if(!selectedItem.dvalue){
                                    this.$set(selectedItem,'dvalue', null);
                                }else{
                                    let val = selectedItem.dvalue;
                                    val = (val * 1) || selectedItem.dvalue;
                                    this.$set(selectedItem,'dvalue', val);
                                }
                                this.$set(selectedItem,'dvalues', []);
                            }

                        }else{
                            this.$set(c,'selected',false);
                        }
                    }else{
                        this.$set(c,'selected',false);
                    }
                    
                    this.$set(c,'active',false);
                });
            });
        },

        doDelete(){ //删除
            // var selectes = this.$refs.dataTable.getCheckboxRecords();
            // if(selectes.length==0){
            //     this.$message(this.msg('请选中一条数据进行操作'));
            //     return;
            // }
            if(!this.curr){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }


            var _this = this;
            this.$confirm(this.fmt('确定要删除视图《%s》吗?', this.curr.name), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.viewSetting.delete+"?ids="+this.curr.id)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.list(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doSort(){ //排序视图显示顺序
            // if(this.query.ownerType.value != 1 && this.query.ownerType.value != 3){
            //     this.$message(this.msg("仅仅支持自定义视图/上级视图调整排序"));
            //     return;
            // }

            var map = {};

            for(var i=0; i<this.datas.length; i++){
                map[this.datas[i].id] = i;
            }

            this.$axios.post(this.$kit.api.viewSetting.seq+"?category="+this.query.category, map)
                .then((result) => {
                    this.$message(result.msg || this.msg('更新成功'));

                    if(result.status){
                        this.ctrlData.sort = false;
                    }

                    this.list(true);
            });
        },

        doDefault({row}){ //设置为默认
            let id = row.id;
            let d = row.defaultedName == "是"? 1:0;

            this.$axios.get(this.$kit.api.viewSetting.defaulted+"?id="+id+"&d="+d+"&category="+row.category)                
                .then((result) => {
                    this.$message(result.msg || this.msg('设置成功'));
                    this.list(true);
            });
        },
        buildBtns(btns){ //构建按钮列表

            let stockBtns = this.dicts.stockBtns;
            
            let newBtns = [];
            stockBtns.forEach(b => {
                if(btns.indexOf(b.name)>-1){
                    let nb = JSON.parse(JSON.stringify(b));
                    newBtns.push(nb);
                    
                    let children = nb.children;
                    if(children && children.length>0){
                        nb.children = [];
                        children.forEach(sub => {
                            if(btns.indexOf(sub.name)>-1){
                                nb.children.push(sub);
                            }
                        })
                    }
                    
                }
            });

            this.stockBtns = newBtns;
        },
        //选中按钮
        checkBtns(btns){

            this.stockBtns.forEach(b => {

                if(btns.indexOf(b.name)>-1){
                    this.$set(b,'selected',true);
                }else{
                    this.$set(b,'selected',false);
                }

                if(b.children && b.children.length>0){
                    b.children.forEach(sub => {
                        if(btns.indexOf(sub.name)>-1){
                            this.$set(sub,'selected',true);
                        }else{
                            this.$set(sub,'selected',false);
                        }
                    })
                }
            })

        },

        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
        openedEdit(){
            this.$nextTick(() => {
                this.$refs.editDg.initForm();
            });
        },
        setSpecFlag(){ //设置为仓储视图

            let label = "";
            let flag = 0;

            if(this.curr.specFlag == 1){
                label = this.fmt("确定要取消《%s》的默认仓储设置吗？", [this.curr.name]);
                flag = 0;
            }else{
                label = this.fmt("确定要将《%s》设置为默认仓储视图吗？", [this.curr.name]);
                flag = 1;
            }
            
            this.setSetting(label, 0, flag);
        },
        setUsually(){
            let label = "";
            let flag = 0;

            if(this.curr.hasUsually == 1){
                label = this.fmt("确定要取消《%s》的常用视图的设置吗？", [this.curr.name]);
                flag = 0;
            }else{
                label = this.fmt("确定要将《%s》设置为常用视图吗？", [this.curr.name]);
                flag = 1;
            }
            
            this.setSetting(label, 1, flag);
        },
        changeUsually(value,name,id){
            let label = "";
            let flag = value;

            if(value == 0){
                label = this.fmt("确定要取消《%s》的常用视图的设置吗？", [name]);
            }else{
                label = this.fmt("确定要将《%s》设置为常用视图吗？", [name]);
            }
            
            let _this = this;
            this.$confirm(label, this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.get(_this.$kit.api.viewSetting.setting+"?id="+id+"&f="+flag+"&t=1")
                .then((result) => {
                    _this.$message(result.msg || _this.msg('更新成功'));
                    _this.list(true);
                });
            }).catch((err) => {
                // console.log(err);
                this.$message(_this.msg('取消操作'));
            });
        },

        //type: 0-设置仓库视图，1-设置常用视图
        setSetting(label, type, flag){
            let _this = this;
            this.$confirm(label, this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.get(_this.$kit.api.viewSetting.setting+"?id="+this.curr.id+"&f="+flag+"&t="+type)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('更新成功'));
                    _this.list(true);
                });
            }).catch((err) => {
                // console.log(err);
            });

        }
        
    }
    
}
</script>

<style scoped lang="scss">


</style>
