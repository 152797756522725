<template>
<el-form v-if="viewSetting" ref="form" :model="viewSetting" label-width="80px" size="mini">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('视图名')">
                <el-input v-model="viewSetting.name"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <!--编辑展示字段详情-->
    <h3>{{msg('显示字段')}}</h3>
    <el-row type="flex" justify="space-around">
        <el-col class="transfer" :span="11">
            <h4>{{msg('待选字段')}}</h4>
            <div class="search-wrap">
                <el-input placeholder="请输入内容" v-model="leftInput"
                    @input="leftSearchInput" @clear="leftClearSearch"
                    prefix-icon="el-icon-search" size="mini" class="left-search"
                ></el-input>
            </div>
            <el-scrollbar class="vlist" style="height:350px;">
                <template>
                    <span v-for="table in tables" :key="table.tableName+'.'+table.alias">
                        <h4 class="group-name">{{table.tableLable}}</h4>
                        <template v-for="item in table.columns">
                            <a v-show="!item.selected" :key="item.colName"
                                :class="['col-item', {'active': item.active} ]" 
                                @click="leftItemClick(item)" 
                                @dblclick="leftItemDbClick(item)" 
                            >{{item.label}}</a>
                        </template>
                    </span>
                </template>
            </el-scrollbar>
        </el-col>
        <el-col class="transfer" :span="10">
           <h4>{{msg('已选字段')}}</h4>
           <div class="search-wrap">
                <el-input placeholder="请输入内容" prefix-icon="el-icon-search" size="mini" 
                    @input="rightSearchInput" @clear="rightClearSearch"
                    class="right-search" v-model="rightInput"
                    ></el-input>
           </div>
            <el-scrollbar class="vlist" style="height:350px;">
                <template>
                    <draggable v-model="viewSetting.details">
                        <transition-group>
                            <a v-for="(detail, idx) in viewSetting.details" :key="idx+detail.colName"
                                    v-show="detail.show"
                                    :class="['col-item', {'active': detail.active} ]"   
                                    href="javascript:void(0);" 
                                    @click="rightItemClick(detail, idx)" 
                                    @dblclick="rightItemDbClick(detail, idx)" 
                                >{{detail.label}}<span v-if="detail.nlabel">({{detail.nlabel}})&nbsp;</span><i v-show="detail.editIcon" class="iconfont">&#xe686;</i>
                            </a>
                        </transition-group>
                    </draggable>
                </template>
            </el-scrollbar>
        </el-col>
    </el-row>
    <div style="text-align:center; margin-top:10px;">
        <el-button size="small" @click="fieldToRight" round icon="el-icon-d-arrow-right" plain></el-button>
        <el-button size="small" @click="fieldToLeft" round icon="el-icon-d-arrow-left" plain></el-button>
    </div>

    <!--编辑字段展示格式-->
    <div v-if="selectedDetail" 
        style="margin: 15px 0px 10px 15px; text-align:center;">{{msg('编辑字段格式')}}</div>
    <el-row v-if="selectedDetail">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('编辑的字段')+':'" label-width="120px">
                <el-input v-model="selectedDetail.label" readonly="readonly"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('自定义显示名称')+':'" label-width="120px">
                <el-input v-model="selectedDetail.nlabel"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="selectedDetail">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('列宽')+':'">
                <el-input v-model="selectedDetail.width"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('是否启用排序')+':'" label-width="120px">
                <el-switch
                    v-model="selectedDetail.sortable"
                    :active-value="1"
                    :inactive-value="0"
                >
                </el-switch>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="selectedDetail">
        <el-col class="selected-filter">
            <el-form-item :label="msg('列筛选类型')+':'" label-width="120px">
                <el-radio-group v-model="selectedDetail.filterType">
                    <el-radio v-for="item in dicts.filterTypes" :label="item.value" :key="item.value">{{item.label}}</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="selectedDetail && selectedDetail.filterType=='type'">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('待筛选内容')+':'" label-width="120px">
                <el-select v-model="selectedDetail.filterOptionKey" size="mini" placeholder="请选择" >
                    <el-option v-for="d in filterOptionTypes" 
                        :key="d.key" :label="d.label" :value="d.key"></el-option>
                </el-select> 
             </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" v-if="getDicts(selectedDetail.filterOptionKey)" style="padding-left:15px;">
            <el-tag style="margin-right:8px;margin-bottom:8px;" v-for="opt in getDicts(selectedDetail.filterOptionKey)" type="danger" :key="opt.value">{{opt.label}}</el-tag>
        </el-col>

    </el-row>
    
    <h3>{{msg('查询条件')}}</h3>
    <el-row type="flex" justify="space-around">
        <el-col class="transfer" :span="11">
            <h4>{{msg('待选字段')}}</h4>
            <div class="search-wrap">
                <el-input placeholder="请输入内容" v-model="qleftInput"
                    @input="qleftSearchInput" @clear="qleftClearSearch"
                    prefix-icon="el-icon-search" size="mini" class="left-search"
                ></el-input>
            </div>
            <el-scrollbar class="vlist" style="height:350px;">
                <template>
                    <span v-for="table in queryTables" :key="table.tableName + '.'+table.alias">
                        <h4 class="group-name" >{{table.tableLable}}</h4>
                        <template v-for="item in table.columns">
                            <a v-show="!item.selected" :key="item.colName"
                                :class="['col-item', {'active': item.active} ]" 
                                @click="leftItemClick(item)" 
                                @dblclick="qleftItemDbClick(item)" 
                            >{{item.label}}</a>
                        </template>
                    </span>
                    
                </template>
            </el-scrollbar>
        </el-col>
        <el-col class="transfer" :span="10">
           <h4>{{msg('已选字段')}}</h4>
           <div class="search-wrap">
                <el-input placeholder="请输入内容" prefix-icon="el-icon-search" size="mini" 
                    @input="qrightSearchInput" @clear="qrightClearSearch"
                    class="right-search" v-model="qrightInput"
                    ></el-input>
           </div>
            <el-scrollbar class="vlist" style="height:350px;">
                <template>
                    <draggable v-model="viewSetting.queries">
                        <transition-group>
                            <a v-for="(query, idx) in viewSetting.queries" :key="idx+query.colName"
                                    v-show="query.show"
                                    :class="['col-item', {'active': query.active} ]"   
                                    href="javascript:void(0);" 
                                    @click="qrightItemClick(query, idx)" 
                                    @dblclick="qrightItemDbClick(query, idx)" 
                                >{{query.label}}<span v-if="query.nlabel">({{query.nlabel}})</span>&nbsp;<i v-show="query.editIcon" class="iconfont">&#xe686;</i>
                            </a>
                        </transition-group>
                    </draggable>
                </template>
            </el-scrollbar>
        </el-col>
    </el-row>
    <div style="text-align:center; margin-top:10px;">
        <el-button size="small" @click="queryToRight" round icon="el-icon-d-arrow-right" plain></el-button>
        <el-button size="small" @click="queryToLeft" round icon="el-icon-d-arrow-left" plain></el-button>
    </div>

    <!--编辑查询条件-->
    <div v-if="selectedQuery" 
        style="margin: 15px 0px 10px 15px; text-align:center;">{{msg('编辑查询条件')}}</div>
    <el-row v-if="selectedQuery">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('编辑的查询条件')+':'" label-width="120px">
                <el-input v-model="selectedQuery.label" readonly="readonly"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="selectedQuery">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('自定义显示名称')+':'" label-width="120px">
                <el-input v-model="selectedQuery.nlabel"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('提示语')+':'">
                <el-input v-model="selectedQuery.placeholder"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="selectedQuery">
        <el-col :xs="24" :sm="12"  class="selected-filter">
            <el-form-item :label="msg('查询类型')+':'" label-width="100px">
                <el-select v-model="selectedQuery.type" size="mini" placeholder="请选择">
                    <el-option v-for="d in dicts.queryTypes" 
                        :key="d.key" :label="d.label" :value="d.key"></el-option>
                </el-select> 
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" v-if="selectedQuery.type<3 && dicts.queryTypes[selectedQuery.type]">
            <el-form-item :label="msg('筛选方式')+':'" label-width="100px">
                <el-select v-model="selectedQuery.op" size="mini" placeholder="请选择" >
                    <el-option v-for="d in dicts.queryTypes[selectedQuery.type].value" 
                        :key="d.value" :label="d.label" :value="d.value"></el-option>
                </el-select> 
             </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="selectedQuery && selectedQuery.type === 2">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('待筛选内容')+':'" label-width="120px">
                <el-select v-model="selectedQuery.filterOptionKey" size="mini" placeholder="请选择">
                    <el-option v-for="(d, idx) in filterOptionTypes" 
                        :key="idx" :label="d.label" :value="d.key"></el-option>
                </el-select> 
             </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" v-if="getOptions(selectedQuery)" style="padding-left:15px;">
            <el-tag style="margin-right:8px; margin-bottom:8px;" v-for="(opt, idx) in selectedQuery.options" type="danger" :key="idx">{{opt.label}}</el-tag>
        </el-col>
    </el-row>
    <el-row v-if="selectedQuery">
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('默认值')+':'" label-width="120px">
                <span v-if="selectedQuery.type == 0">
                    <el-input v-if="selectedQuery.op != 6" v-model="selectedQuery.dvalue"  clearable></el-input>
                    <el-select v-else v-model="selectedQuery.dvalues" 
                        multiple filterable allow-create
                        size="mini" :placeholder="msg('按确认键输入下一个值')">
                    </el-select> 
                </span>
                <el-input v-else-if="selectedQuery.type == 1" v-model="selectedQuery.dvalue"  clearable></el-input>
                <span v-else-if="selectedQuery.type == 2">
                    <el-select v-if="selectedQuery.op === 0" v-model="selectedQuery.dvalue" size="mini" placeholder="请选择" clearable>
                        <el-option v-for="(d, idx) in selectedQuery.options" 
                            :key="idx" :label="d.label" :value="d.value"></el-option>
                    </el-select> 
                    <el-select v-else v-model="selectedQuery.dvalues" multiple size="mini" placeholder="请选择" clearable>
                        <el-option v-for="(d, idx) in selectedQuery.options" 
                            :key="idx" :label="d.label" :value="d.value"></el-option>
                    </el-select> 
                </span>
                <span v-else-if="selectedQuery.type == 3 || selectedQuery.type == 4">
                    <el-select size="mini" placeholder="请选择" v-model="selectedQuery.dvalue" clearable>
                        <el-option v-for="(d, idx) in dicts.dateRanges" 
                            :key="idx" :label="d.label" :value="d.value"></el-option>
                    </el-select> 
                </span>
             </el-form-item>
             <el-form-item :label="msg('是否显示')+':'" label-width="100px">
                <el-switch
                    v-model="selectedQuery.disabled"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="是"
                    inactive-text="否"
                    :active-value="0"
                    :inactive-value="1"
                    >
                </el-switch>
             </el-form-item>
        </el-col>
    </el-row>

    <el-row v-if="category == 1">
        <h3>{{msg('显示按钮')}}</h3>
        <span v-for="(btn, idx) in stockBtns" :key="idx">
            <el-checkbox
                border size="mini" class="pts-checkbox"
                v-model="btn.selected"  :label="msg(btn.name)" >
            </el-checkbox>

            <template v-if="btn.children && btn.children.length>0 && btn.selected" >
                <span v-for="(sub, idx1) in btn.children" :key="idx1">
                    <el-checkbox 
                        border size="mini" class="pts-checkbox"
                        v-model="sub.selected"  :label="msg(sub.name)" >
                    </el-checkbox>
                </span>
            </template>
        </span>
    </el-row>

    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('设置为默认视图')" label-width="120px">
                <el-radio-group v-model="viewSetting.defaulted">
                    <el-radio :label="1" >{{msg("是")}}</el-radio>
                    <el-radio :label="0" >{{msg("否")}}</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center; margin-top:15px;">
        <el-button type="primary" size="mini" @click="onSubmit(1)"><i class="el-icon-check"></i><span>{{msg('保存为新视图')}}</span></el-button>
        <el-button type="primary" size="mini" @click="onSubmit(0)"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
  
</el-form> 
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'ViewSettingDetail',
  components:{
      draggable: draggable,
  },
  data(){
    return{
        leftInput:null,
        rightInput:null,

        qleftInput:null,
        qrightInput:null,

        selectedDetail: null,
        selectedQuery: null,

        filterType: null,

        timer: null,

        searching:false,
        expCompanys:[],
    }
  },
  props:{
      tables:Array,    //显示字段，左边待选列表
      queryTables: Array,  //查询条件，左边待选列表
      viewSetting: Object,
      filterOptionTypes: Array, //下拉框属性
      stockBtns: Array, //库存按钮字段
      category: Number,  //0-账户，1-库存
  },
  mounted: function(){
  },
  methods:{
    initForm(){
        this.leftInput = null;
        this.rightInput = null;
        this.qleftInput = null;
        this.qrightInput = null;
        
        this.type = 0;
        this.selectedDetail = null;
        this.selectedQuery = null;

        this.filterType = null; 
        this.searching = false;
        this.getCompanys();
    },
    getCompanys(){
        this.$axios.get(this.$kit.api.getExpCompany)
            .then((result)=> {
                if(result.status){
                    this.expCompanys = result.data;
                }
            });
    },
    onSubmit(isNew){

        if(!this.viewSetting.name){
            this.$message(this.msg("视图名不能为空"));
            return;
        }

        var url = null;
        if(this.viewSetting.id==null){
            url = this.$kit.api.viewSetting.create;
        }else{
            url = this.$kit.api.viewSetting.update;
        }
        
        //排序
        for(let i=0; i<this.viewSetting.details.length; i++){
            this.viewSetting.details[i].seq = i;
        }

        for(let i=0; i<this.viewSetting.queries.length; i++){
            this.viewSetting.queries[i].seq = i;
        }

        //给重复的字段取别名
        //表名首字母+加字段名
        for(let i=0; i<this.viewSetting.details.length; i++){
            var item = this.viewSetting.details[i];
            
            for(let j = i+1; j < this.viewSetting.details.length; j++){
                var item1 = this.viewSetting.details[j];

                if(item1 && item.fieldName == item1.fieldName){
                    if(item1.fieldName != 'code' && item1.fieldName != 'status'){
                        var tablesplit = item1.tableName.split("_");
                        var newName = "";
                        tablesplit.forEach(t => newName += t.substring(0,1))
                        item1.fieldName = newName + item1.fieldName;
                        item1.hasAlias = 1; 
                    }
                }
            }
        }

        //整理查询条件的默认值，
        this.viewSetting.queries.forEach( e => {
            if(e.op === 6 || e.op === 8){                
                e.dvalue = null;
                if(e['dvalues'] && e.dvalues.length>0){
                    e.dvalue = e.dvalues.join(",");
                }
            }
            delete e.options;
        });

        if(this.category == 1){
            this.viewSetting.btns = [];
            this.stockBtns.forEach(b => {

                if(b.selected){
                    this.viewSetting.btns.push(b.name);

                    if(b.children && b.children.length>0){
                        b.children.forEach(sub => {
                            if(sub.selected){
                                this.viewSetting.btns.push(sub.name);
                            }
                        });
                    }

                }

            });
        }
        

        var _this = this;
        this.$axios.post(url+"?isNew="+isNew, this.viewSetting)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')));
                    if(result.status){
                        _this.$emit("close-dg", true); 
                    }
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
            });
    },
    //左边单击, 选中点击项
    leftItemClick(item){
        item.active = !item.active;
    },
    //显示字段，左边双击， 移动到右变
    leftItemDbClick(item){
        item.active = false;
        item.selected = true;
        var newField = {
            nlabel: null,
            sortable: 0,
            show: true,
            editIcon:false,
            filterType: 'search',   //筛选条件类型
            hasAlias: 0,
            filterOptionKey: null, //筛选类型为分类筛选时，指定的分类的key值
            width: 100,
            tableAlias: item.tableAlias,
        }
        this.viewSetting.details.push(Object.assign(newField, item));
    },
    //字段。点击批量移动到右边
    fieldToRight(){
        this.tables.forEach(t => {
            t.columns.forEach(c => {
                if(c.active){
                    this.leftItemDbClick(c);
                }
            });
        });
    },
    //筛选条件，左边双击， 移动到右变
    qleftItemDbClick(item){
        item.active = false;
        item.selected = true;
        var newField = {
            show: true,
            type: 0,
            dvalue: null,
            dvalues: [],
            editIcon:false,
            op: 0,   //筛选条件类型
            nlabel: null, //自定义显示名
            placeholder: null, //提示语
            tableAlias: item.tableAlias,
            disabled:0,
        }
        this.viewSetting.queries.push(Object.assign(newField, item));
    },
    //查询条件。点击批量移动到右边
    queryToRight(){
        this.queryTables.forEach(t => {
            t.columns.forEach(c => {
                if(c.active){
                    this.qleftItemDbClick(c);
                }
            });
        });
    },
    //显示字段，右边单击， 编辑详情
    rightItemClick(detail, idx){
        clearTimeout(this.timer);  //首先清除计时器
        this.timer = setTimeout(() => {
            detail.active = !detail.active;
            this.viewSetting.details.forEach( e => {
                e.editIcon = false;
            });
            if(detail.active == true){
                this.selectedDetail = detail;
                this.selectedDetail.editIcon = true;
            }
        }, 300);

    },
    //筛选条件，右边单间，编辑详情
    qrightItemClick(query, idx){
        clearTimeout(this.timer);  //首先清除计时器
        this.timer = setTimeout(() => {
            query.active = !query.active;
            this.viewSetting.queries.forEach( e => {
                e.editIcon = false 
            });
            
            if(query && query.active == true){
                this.selectedQuery = query;
                this.selectedQuery.editIcon = true;
            }
            
        }, 300);

    },
    //右边双击， 从右边移除，并添加到左边
    rightItemDbClick(detail, idx){
        clearTimeout(this.timer);
        detail.active = false;
        this.viewSetting.details.splice(idx, 1);

        this.tables.forEach(t => {
            if(detail.tableName == t.tableName && detail.tableAlias == t.alias){
                t.columns.forEach(c => {
                    if(c.colName == detail.colName){
                        c.active = false;
                        c.selected = false;
                        this.selectedDetail = null;
                    }
                });
            }
        });

    },
    //字段，右边移动到左边
    fieldToLeft(){
        let actives = this.viewSetting.details.filter(e => e.active);
        actives.forEach(a => {
            a.active = false;

            this.tables.forEach(t => {
                if(t.tableName == a.tableName && a.tableAlias == t.alias){
                    t.columns.forEach(c => {
                        if(c.colName == a.colName){
                            c.active = false;
                            c.selected = false;
                            this.selectedDetail = null;
                        }
                    });
                }
            });

            let idx = this.viewSetting.details.findIndex(e => e.tableName == a.tableName && e.colName == a.colName);
            if(idx > -1){
                this.viewSetting.details.splice(idx, 1);
            }
        });
    },
    //筛选条件，右边双击， 从右边移除，并添加到左边
    qrightItemDbClick(query, idx){
        clearTimeout(this.timer);
        query.active = false;
        this.viewSetting.queries.splice(idx, 1);

        this.queryTables.forEach(t => {
            if(query.tableName == t.tableName && query.tableAlias == t.alias){
                t.columns.forEach(c => {
                    if(c.colName == query.colName){
                        c.active = false;
                        c.selected = false;
                        this.selectedQuery = null;
                    }
                });
            }
        });
        
    },
    //查询。点击批量右边移动到左边
    queryToLeft(){
        let actives = this.viewSetting.queries.filter(e => e.active);
        actives.forEach(a => {
            a.active = false;

            this.queryTables.forEach(t => {
                if(t.tableName == a.tableName && a.tableAlias == t.alias){
                    t.columns.forEach(c => {
                        if(c.colName == a.colName){
                            c.active = false;
                            c.selected = false;
                            this.selectedQuery = null;
                        }
                    });
                }
            });

            let idx = this.viewSetting.queries.findIndex(e => e.tableName == a.tableName && e.colName == a.colName);
            if(idx > -1){
                this.viewSetting.queries.splice(idx, 1);
            }
        });
    },
  
    //显示字段，左边搜索
    leftSearchInput(val){
        
        if(this.searching){
            return;
        }

        this.searching = true;
        this.tables.forEach(t => {
            t.columns.forEach(c => {
                if(c.label.indexOf(val) == -1){
                    c.selected = true;
                }else{
                    c.selected = false;
                }
            });
        });
        this.searching = false;
    }, 
    //筛选条件，左边搜索
    qleftSearchInput(val){
        
        if(this.searching){
            return;
        }

        this.searching = true;
        this.queryTables.forEach(t => {
            t.columns.forEach(c => {
                if(c.label.indexOf(val) == -1){
                    c.selected = true;
                }else{
                    c.selected = false;
                }
            });
        });
        this.searching = false;
    }, 
    //显示字段，清除左边的搜索条件
    leftClearSearch(){
        this.tables.forEach(t => {
            t.columns.forEach(c => {
                c.selected = false;
            });
        });
    },
    //筛选条件，清除左边的搜索条件
    qleftClearSearch(){
        this.queryTables.forEach(t => {
            t.columns.forEach(c => {
                c.selected = false;
            });
        });
    },
    //显示字段，右边搜索条件
    rightSearchInput(val){
        
        if(this.searching){
            return;
        }

        this.searching = true;

        this.viewSetting.details.forEach( e => {
            if(e.label.indexOf(val) > -1 || (e.nlabel && e.nlabel.indexOf(val) > -1)){
                e.show = true;
            }else{
                e.show = false;
            }
        });
       
        this.searching = false;
    },
     //筛选条件，右边搜索条件
    qrightSearchInput(val){
        
        if(this.searching){
            return;
        }

        this.searching = true;

        this.viewSetting.queries.forEach( e => {
            if(e.label.indexOf(val) > -1 || (e.nlabel && e.nlabel.indexOf(val) > -1)){
                e.show = true;
            }else{
                e.show = false;
            }
        });
       
        this.searching = false;
    },
    //显示字段，清除右边搜索条件
    rightClearSearch(){
       this.viewSetting.details.forEach( e => {
            e.show = true;
        });
    },
    //筛选条件，清除右边搜索条件
    qrightClearSearch(){
       this.viewSetting.queries.forEach( e => {
            e.show = true;
        });
    },
    //获取字典
    getOptions(selectQuery){
        let options = this.getDicts(selectQuery.filterOptionKey);
        if(selectQuery.filterOptionKey == 'expCompany'){
            options = this.expCompanys;
        }
        this.$set(selectQuery,'options', options);
        return options;
    }
  }
}
</script>

<style scoped lang="scss">
.transfer h4{margin-top:8px; margin-bottom:8px; padding-left:10px;}
.transfer{border: 1px solid #d7dae2; border-radius: 4px; background: #f5f7fa;}
.transfer .group-name{padding-top:10px; padding-bottom:5px; margin:0px;}
.group-name{ border-bottom: 1px solid #dcdfe6;}
.search-wrap{padding: 0px 15px 10px; border-bottom:1px solid #d7dae2;  box-shadow: 0px 4px 4px rgba(0,0,0, 0.1);}

.col-item{display:block; color: #606266; text-decoration: none; padding:3px 10px; cursor:pointer;}
.col-item:hover,.col-item.active{color: #409eff; background: rgba(64, 158, 255, 0.1); }
.vlist{background: white;}
.vlist .el-scrollbar__wrap{overflow-x:hidden;}

.selected-filter >>> .el-radio-group > label{margin-top:6px; margin-bottom:6px;}
.pts-checkbox{
    margin: 5px 15px 5px 0px !important;
}
</style>
